<template>
  <ol v-if="titles.length > 0">
    <li v-for="title in titles" :key="title.id">
      <a :href="`#${title.id}`">{{ title.text }}</a>
    </li>
  </ol>
</template>
<script>
export default {
  props: ["contentElement", "level"],
  data () {
    return {
      titles: []
    }
  },
  watch: {
    contentElement: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }
        this.$nextTick(() => {
          let i = 1
          this.titles = []
          v.querySelectorAll(`h${this.level}`).forEach((e) => {
            e.id = e.id || `toc-${i}`
            this.titles.push({
              id: e.id,
              text: e.textContent
            })
            i += 1
          })
        })
      }
    }
  }
}
</script>